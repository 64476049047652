.Testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}

.left-t :nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.left-t :nth-child(2),
.left-t :nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}

.left-t :nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 3.8px;
    line-height: 40px;
}

.right-t{
    flex: 1;
    position: relative;
}

.photo{
    position: aboslute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    margin-left: 19rem;
    margin-top: 1rem;
    z-index: -1;
}

.right-frame1{
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 10rem;
    top: 0.1rem;
    z-index: -3;
}

.right-frame2{
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 8rem;
    top: 3rem;
    z-index: -2;
    background: var(--planCard);
}

.arrows{
    display: flex;
    flex-direction: column;
    position: relative;
    /* justify-content: space-evenly; */
    gap: 1rem;
    bottom: 2rem;
    left: 3rem;;
}
.arrows img{
    width: 1.5rem;
    height: 1rem;
    cursor: pointer;
}

@media screen and (max-width : 768px) {
    .Testimonials{
        flex-direction: column;
    }
    .left-t :nth-child(2)
    .left-t :nth-child(3){
        font-size: xx-large;
    }
    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .right-t div{
        position: relative;
        display: none;
    }
    .right-t img{
        top: 0;
        right: 9.5rem;
        position: relative;
        align-self: center;
    }
    .right-t :last-child{
        display: block;
        top: -1.1rem;
        bottom: 0;
        right: 2rem;
        justify-content: space-evenly;
    }
}